import React, { useState, useEffect, useRef } from "react";
import { useEventListener } from "../../../hooks/UseEventListener";
import useIsBrowser from "../../../hooks/useIsBrowser";
import { chevron } from "../../../shared/icons";
import styles from "./CategoriesNavMobile.module.scss";

const CategoriesNavMobile = ({ categories, selectCategory, currentCategory }) => {
    const mainWrapper = useRef<HTMLDivElement>(null as any);
    const [showOptions, setShowOptions] = useState(false);
    const [listHeight, setListHeight] = useState<number>(null as any);

    const { isBrowser } = useIsBrowser();

    const handleCategorySelection = category => {
        setShowOptions(false);
        selectCategory(category);
    };

    const calculateListHeight = () => {
        if (!mainWrapper?.current) return;

        const { top, height } = mainWrapper.current.getBoundingClientRect();
        setListHeight(window.innerHeight - (top + height));
    };

    if (isBrowser) {
        if (showOptions) {
            document.body.classList.add(styles.noOverflow);
        } else {
            document.body.classList.remove(styles.noOverflow);
        }
    }

    useEffect(calculateListHeight, [showOptions]);

    useEventListener("resize", calculateListHeight);

    return (
        <nav
            ref={mainWrapper}
            className={styles.categoriesNav}
            style={{
                ["--calculatedViewportHeight" as string]: `${listHeight}px`,
            }}
        >
            <button
                className={styles.categorySelect}
                onClick={() => setShowOptions(!showOptions)}
            >
                <span>Formats</span>

                <span className={styles.arrow}>
                    {!showOptions ? chevron.down : chevron.top}
                </span>
            </button>

            {showOptions && (
                <div className={styles.categoryList}>
                    {categories.map(category => {
                        if (category.classes.length > 0) {

                            return (<div
                                key={category.id}
                                onClick={() => handleCategorySelection(category)}
                            >
                                {category.name}
                            </div>
                            )
                        }
                    }
                    )
                    }
                </div>
            )}
        </nav>
    );
};

export default CategoriesNavMobile;
