import React, { PropsWithChildren, ReactElement, useRef, useEffect, useState, HTMLProps } from "react";
import Helmet from "../../components/Helmet";
import CategoryBox from "./CategoryBox";
import styles from "./classes.module.scss";
import CategoriesNavMobile from "./CategoriesNavMobile";
import GrandientImageBanner from "../../components/Widgets/GradientImageBanner";
import ScheduleDownload from "../../components/Widgets/ScheduleDownload";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import HeroMedia from "../../components/Widgets/HeroMedia";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";

const scrollThresholdMin = 0;
const scrollThresholdMax = -100;
const autoScrollThreshold = 5;

interface ClassPageProps {
    pageContext: any
};

const ClassesPage = (props: PropsWithChildren<ClassPageProps>): ReactElement => {
    const { data, relations: { categories, try_us, schedule_download } } = props.pageContext;    
    const bannerProps = {
        banner_title: try_us.dataRaw.title,
        banner_text: try_us.dataRaw.subtitle,
        banner_background: try_us.dataRaw.background_image,
        banner_cta_text: try_us.dataRaw.cta_text,
        banner_cta_url: try_us.dataRaw.cta_url,
        banner_gradient_color: try_us.dataRaw.grandient_color,
        banner_text_color: try_us.dataRaw.text_color
    }

    const containerRef = useRef<HTMLDivElement>(null);
    const titleContainerRef = useRef<HTMLDivElement>(null);
    const scrollPosition = useRef(0);
    const [currentCategory, setCurrentCategory] = useState(categories[0]);

    useEffect(() => {
        document!.addEventListener("scroll", handleScroll);

        return () => document!.removeEventListener("scroll", handleScroll);
    }, []);


    const handleScroll = () => {
        scrollPosition.current = document.documentElement.scrollTop;

        const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight'));
        const currentCategoryTitleHeight = isDesktop ? titleContainerRef.current?.offsetHeight : 75;
        const topOffset = headerHeight + currentCategoryTitleHeight;

        for (let childCategoryEl of containerRef.current.children) {
            const category = categories.find((x: any) => x.id === parseInt(childCategoryEl.id, 10));
            const elemOffset = childCategoryEl.getBoundingClientRect().top - topOffset;
            const categoryIsCurrent = parseInt(childCategoryEl.id) === currentCategory.id;
            const topReached = (elemOffset <= scrollThresholdMin && elemOffset >= scrollThresholdMax);

            if (topReached && !categoryIsCurrent) {
                setCurrentCategory(category);
            }
        }
    };

    const selectCategory = (category: any) => {
        const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight'));
        const currentCategoryTitleHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--mobileNav-height')) + 15;
        const topOffset = headerHeight + currentCategoryTitleHeight;

        const categoryContainer = Array.from(containerRef.current.children).find(x => Number(x.id) === category.id);
        const categoryContainerTop = categoryContainer?.getBoundingClientRect().top;
        const slideTop = categoryContainerTop - topOffset + scrollPosition.current + autoScrollThreshold;

        window.scrollTo({ top: slideTop, left: 0, behavior: "smooth" });
    };

    const isDesktop = useWindowWidth(heroDesktopMinWidth)
    const background = isDesktop ? data.hero_background : data.mobile_background || data.hero_background

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Classes Formats")}
                description={getSeoDescription(data, "Classes Formats page")}
                keywords={getSeoKeywords(data, "")} 
            />
            <HeroMedia data={data} height={80}/>

            <main className={styles.mainGrid}>
                {
                    isDesktop ? (
                        <aside className={styles.categoriesSidebar}>
                            <nav className={styles.categoriesListWrapper}>
                                <ul className={styles.categoriesList}>
                                    {categories.map((category: any) => {
                                        if (category.classes.length > 0){
                                            return (
                                                <li key={category.id}>
                                                    <button onClick={() => selectCategory(category)}>
                                                        {category.name}
                                                    </button>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </nav>
                        </aside>
                    ) : (
                        <CategoriesNavMobile categories={categories} selectCategory={selectCategory} currentCategory={currentCategory}/>
                    )
                }

                <section className={styles.classesContainer}>
                    { isDesktop && (
                        <div className={`${styles.titleContainer} wideBox`} ref={titleContainerRef}>
                            <h1 className={`${styles.currentCategory} px-2`}>Formats</h1>
                        </div>
                        )
                    }
                    <div className={`${styles.categoryList} wideBox`} ref={containerRef}>
                        {
                            categories.map((category: any) => (
                                <CategoryBox
                                    key={`cat-${category.id}`}
                                    category={category}
                                    currentCategory={currentCategory}
                                />
                            ))
                        }
                    </div>
                </section>
            </main>

            <GrandientImageBanner data={{ ...bannerProps }} />

            { schedule_download.dataRaw && <ScheduleDownload {...schedule_download} />}
        </>
    )
}

export default ClassesPage;

