import { Link } from "gatsby";
import React, { PropsWithChildren, ReactElement, useState, useLayoutEffect, useRef } from "react";
import styles from "./ClassCard.module.scss";

interface ClassCardProps {
    image: { url: string; alt: string};
    title: [{ text: string }];
    about: [{ text: string }];
    uid: string
}


const ClassCard = (props: PropsWithChildren<ClassCardProps>): ReactElement => {
    const { image, title, about, uid } = props;

    return (
        <div
            className={`${styles.container}`}
        >
            <img src={image?.url ? image.url : "/assets/image/ClassPlaceHolder.jpg" } alt={image?.alt ? image.alt : "Class Room" } className={styles.imageContainer}></img>

            <div className={styles.information}>
                <h3 className={styles.classTitle}>
                    {title[0]?.text}
                </h3>
                <Link to={`${uid}`} className={styles.link}>See More</Link>
            </div>
        </div>
    );
};

export default ClassCard;
