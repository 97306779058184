import React, { PropsWithChildren, ReactElement } from "react";
import styles from "./CategoryBox.module.scss";
import ClassCard from "../ClassCard";

interface CategoryBoxProps
{
    category: any,
    currentCategory: any
};

const CategoryBox = (props: PropsWithChildren<CategoryBoxProps>): ReactElement =>
{
    const { category, currentCategory } = props;
    const categoryIsCurrent = currentCategory.id === category.id;
    return (
        <>
        { category.classes.length > 0 &&
            <div id={category.id} className={styles.container}>
                <div className={`${categoryIsCurrent ? styles.current : ""}`}>
                    <h2 className={styles.sectionTitle}>{category.name}</h2>
                </div>
                <div className={styles.cardsWrapper}>
                {
                    category.classes.map((_class: any) => (
                        <ClassCard 
                            key={_class.id} 
                            image={_class.dataRaw.class_img} 
                            title={_class.dataRaw.page_title}
                            about={_class.dataRaw.about_text}
                            uid={_class.uid}
                        />
                    ))
                }
                </div>
            </div>
        }
        </>
    )
}

export default CategoryBox;